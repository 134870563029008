<template>
  <div>
    <vs-input icon-after="true" :v-validate="vValidate" :data-vv-as="dataVvAs" :name="name" icon-pack="feather" icon="icon-search" readonly :label="label" class="w-full" v-model="nameBrowse" @click="browse"></vs-input>
    <input type="text" hidden :value="value">

    <vs-popup :title="'Find ' + label" :active.sync="isPopUp">
      <vs-table :sst="true" search @search="handleSearch" :data="data.data" @sort="handleSort" class="mb-3" >
        <template slot="header">
            <button-browse-contact label="Contact" v-on:onChangeContact="addExistingContact"></button-browse-contact>
          </template>
        <template slot="thead">
          <vs-th sort-key="name">Name</vs-th>
          <vs-th sort-key="address">Address</vs-th>
          <vs-th sort-key="phone">Phone</vs-th>
          <vs-th sort-key="bill_name">Billing To</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.name">
              <a class="pointer" @click="changeValue(tr.id)">{{tr.name}}</a>
            </vs-td>
            <vs-td label="Address" :data="tr.address">{{tr.address}}</vs-td>
            <vs-td label="Phone" :data="tr.phone">{{tr.phone}}</vs-td>
            <vs-td label="Billing To" :data="tr.bill_name">{{tr.bill_name}}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination :total="data.last_page" v-model="currentPage" class="mt-5"></vs-pagination>
    </vs-popup>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import ButtonBrowseContact from '@browse_button/Contact.vue'

export default {
  props: ['value','label','vValidate','dataVvAs','name','contactId'],
  components:{
    ButtonBrowseContact
  },
  data(){
    return {
      nameBrowse: "",
      currentPage: 1,
      search:null,
      sortKey:null,
      sortVal:null,
      isPopUp:false
    }
  },
  watch:{
    async value(val) {
      if (val) {
        this.changeValue(val)
      } else {
        this.nameBrowse = ""
      }
    },
    search(){
      this.currentPage=1
      this.getData()
    },
    currentPage(){
      this.getData()
    }
  },
  computed:{
    ...mapState({
      data: state => state.contacts.a_rows
    })
  },
  methods:{
    async browse(){
      if (!this.contactId) return this.$vs.notify({
        title: `Oops!`,
        text: `Please choose the name of the customer first`,
        color: `warning`
      });
      await this.getData()
      this.isPopUp=true
    },
    async changeValue(value){
      let {data} = await this.dispatchShow(value)
      this.$emit('input', data.id)
      data.address = data.address || ''
      this.nameBrowse = `${data.name} - ${data.address}`
      this.isPopUp=false
    },
    ...mapActions({
      dispatchIndex: 'contacts/address_list',
      dispatchShow: 'contacts/show',
      dispatchFromExistingContact: 'contacts/storeFromExistingContact'
    }),
    async getData(){
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
        is_active : true,
        id: this.contactId
      }
      await this.dispatchIndex(payload)
    },
    handleSearch(val){
      this.search = val
    },
    handleSort(key,val){
      this.sortKey = key
      this.sortVal = val
      this.getData()
    },
    async addExistingContact(value) {
      let payload = {
          contact_id: this.contactId,
          address_id: value
        }
        try {
          await this.dispatchFromExistingContact(payload)
          await this.getData();
          this.$vs.notify({
            title: 'Success',
            text: 'Your data has been saved successfully',
            color: 'primary'
          })
        } catch (error) {
          this.$vs.notify({
            title: 'Oops!',
            text: error.data.message,
            color: 'danger'
          })
        }
    }
  }
}
</script>

<style lang="scss">
  .pointer {
    cursor: pointer;
  }
</style>