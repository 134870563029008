<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base" v-if="is_filter">
      <vx-card title="Filter">
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-contact
              class="w-full"
              :iscustomer="true"
              name="customer_id"
              label="Customer"
              v-model="customer_id"
              v-on:onChangeContact="changeCustomer"
            ></browse-contact>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-contact-address
              class="w-full"
              :contact-id="filterData.customer_id"
              name="sender_id"
              label="Shipper"
              v-model="filterData.sender_id"
              @input="changeShipper"
            ></browse-contact-address>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
            <browse-contact-address
              class="w-full"
              :contact-id="filterData.customer_id"
              name="receiver_id"
              label="Consignee"
              v-model="filterData.receiver_id"
              @input="changeConsignee"
            ></browse-contact-address>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col lg:w-1/3 md:w-1/2 sm:w-3/4 w-full">
                <small class="ml-2">Created Date</small>
                <div class='flex align-items-center'>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.start_date" @input='changeStartDate'></flat-pickr>
                    <small class='ml-5 mr-5'>Until</small>
                    <flat-pickr :config="flatPickrConfig" class="w-full" v-model="filterData.end_date"  @input='changeEndDate'></flat-pickr>
                </div>
          </div>
        </div>
        <div class="vx-col w-full flex mt-3">
          <vs-button color="warning" @click="resetFilter()">Reset</vs-button>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vx-card title="Job Orders">
        <vs-table
          :sst="true"
          search
          @search="handleSearch"
          :data="data.data"
          @sort="handleSort"
          class="mb-3"
        >
          <template slot="header">
            <vs-button
              color="success"
              class="mr-1 ml-1"
              @click="is_filter = !is_filter"
              >Filter</vs-button
            >&nbsp;
            <vs-button
              color="primary"
              class="mr-1 ml-1"
              :to="{ name: 'job_orders-create' }"
              >Add New</vs-button
            >&nbsp;
            <vs-button
              color="success"
              class="mr-1 ml-1"
              :to="{ name: 'job_orders-import' }"
              >Import Jobs</vs-button
            >
          </template>
          <template slot="thead">
            <vs-th sort-key="code">Job Order No.</vs-th>
            <vs-th sort-key="no_shipment">Shipment No.</vs-th>
            <vs-th sort-key="company">Company</vs-th>
            <vs-th sort-key="customer">Customer</vs-th>
            <vs-th sort-key="shipper">Shipper</vs-th>
            <vs-th sort-key="consignee">Consignee</vs-th>
            <vs-th sort-key="route">Route</vs-th>
            <vs-th sort-key="mode">Mode</vs-th>
            <vs-th sort-key="vehicle_type">Vehicle Type</vs-th>
            <vs-th sort-key="created_at">Created At</vs-th>
            <vs-th sort-key="created_at"></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.code">
                <router-link
                  :to="{ name: 'job_orders-show', params: { id: tr.id } }"
                  >{{ tr.code }}</router-link
                >
              </vs-td>
              <vs-td label="shipment no" :data="tr.no_shipment">{{
                tr.no_shipment
              }}</vs-td>
              <vs-td label="company" :data="tr.company">{{ tr.company }}</vs-td>
              <vs-td label="customer" :data="tr.customer">{{
                tr.customer
              }}</vs-td>
              <vs-td label="shipper" :data="tr.shipper">{{ tr.shipper }}</vs-td>
              <vs-td label="consignee" :data="tr.consignee">{{
                tr.consignee
              }}</vs-td>
              <vs-td label="route" :data="tr.route">{{ tr.route }}</vs-td>
              <vs-td label="mode" :data="tr.mode">{{ tr.mode }}</vs-td>
              <vs-td label="vehicle type" :data="tr.vehicle_type">{{
                tr.vehicle_type
              }}</vs-td>
              <vs-td label="vehicle type" :data="tr.vehicle_type">{{
                tr.created_at
              }}</vs-td>
              <vs-td :data="tr.id" width="10">
                <div class="flex">
                  <vs-button
                    size="small"
                    type="line"
                    :to="{ name: 'job_orders-show', params: { id: tr.id } }"
                    icon-pack="feather"
                    icon="icon-folder"
                  ></vs-button>
                  <vs-button
                    size="small"
                    color="danger"
                    type="line"
                    @click="deletes(tr.id)"
                    icon-pack="feather"
                    icon="icon-trash"
                  ></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          :total="data.last_page"
          v-model="currentPage"
          class="mt-5"
        ></vs-pagination>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import BrowseContact from "@browse/Contact.vue";
import BrowseContactAddress from "@browse/ContactAddress.vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  components: {
    BrowseContact,
    BrowseContactAddress,
    flatPickr
  },
  data() {
    return {
      customer_id: null,
      filterData: {},
      is_filter: false,
      idDelete: null,
      currentPage: 1,
      search: null,
      sortKey: null,
      sortVal: null,
      isModalVisible: false,
      flatPickrConfig: {
            altFormat: 'j M Y',
            altInput: true,
            dateFormat: 'Y-m-d',
      }
    };
  },
  watch: {
    search() {
      this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
    },
    customer_id() {
      this.filterData.sender_id = null;
      this.filterData.receiver_id = null;
      this.getData();
    },
  },
  computed: {
    ...mapState({
      data: (state) => state.job_orders.rows,
    }),
  },
  methods: {
    ...mapActions({
      dispatchIndex: "job_orders/index",
      dispatchDestroy: "job_orders/destroy",
    }),
    async getData() {
      let payload = {
        page: this.currentPage,
        search: this.search,
        sortKey: this.sortKey,
        sortVal: this.sortVal,
      };
      payload = Object.assign(payload, this.filterData);
      await this.dispatchIndex(payload);
    },
    async confirmDelete() {
      try {
        await this.dispatchDestroy(this.idDelete);
        this.getData();
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been deleted successfully`,
          color: `primary`,
        });
      } catch (error) {
        this.$vs.notify({
          title: `Oops!`,
          text: `Looks like something went wrong. please try again later (${error.data.message})`,
          color: `danger`,
        });
      }
    },
    deletes(id) {
      this.idDelete = id;
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDelete,
      });
    },
    handleSearch(val) {
      this.search = val;
    },
    handleSort(key, val) {
      this.sortKey = key;
      this.sortVal = val;
      this.getData();
    },
    async changeCustomer(data) {
      this.filterData.customer_id = data.id;
      await this.getData();
    },
    async changeShipper(data) {
      this.filterData.sender_id = data;
      await this.getData();
    },
    async changeConsignee(data) {
      this.filterData.receiver_id = data;
      await this.getData();
    },
    async changeStartDate(data) {
      this.filterData.start_date = data;
      await this.getData();
    },
    async changeEndDate(data) {
      this.filterData.end_date = data;
      await this.getData();
    },
    async resetFilter() {
      this.filterData = {};
      await this.getData();
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style>
</style>