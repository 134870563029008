<template>
  <span>
    <vs-button @click="openItems()">Add From Existing Contact</vs-button>
    <browse-contact class="hidden" :is_open="is_open" :isvendor="isvendor" :iscustomer="iscustomer" :isdriver="isdriver" :name="name" :label="label" @input="getData"></browse-contact>
  </span>
</template>

<script>
import {mapState,mapActions} from 'vuex'
import BrowseContact from '@browse/Contact.vue'

export default {
  props: ['value','label','vValidate','dataVvAs','name','isvendor','iscustomer','isdriver'],
  components:{
    BrowseContact
  },
  data(){
    return {
      is_open: false
    }
  },
  methods:{
    openItems(){
        this.is_open = true
        setTimeout(() => {
            this.is_open = false
        }, 2000)
    },
    getData(value) {
      this.is_open = false
      this.$emit('onChangeContact', value)
    }
  }
}
</script>